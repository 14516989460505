import './polyfills'
import { PluginRegistry } from 'plugin-system'
import { PluginProvider, SlotRenderer } from 'plugin-system'
import React from 'react'
import ReactDOM from 'react-dom/client'
import pluginsConfig from '../plugins.config'

if (import.meta.env.DEV) {
  if (!localStorage.debug) {
    localStorage.debug = '*'
  }
}

export const pluginRegistry = new PluginRegistry()
if (import.meta.hot) {
  pluginRegistry.uninstallAll()
}
pluginRegistry.installMany(pluginsConfig).forEach(console.error)

const rootElement = document.getElementById('root')
rootElement &&
  ReactDOM.createRoot(rootElement).render(
    <PluginProvider pluginRegistry={pluginRegistry}>
      <SlotRenderer slot="shell.content" />
    </PluginProvider>,
  )
