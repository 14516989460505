import { AuthPlatformSetEvent, AuthTokenSetEvent } from 'common-ui'
import { Plugin, PluginInfo, PluginRegistry } from 'plugin-system'
import ReactGA from 'react-ga4'

export class GoogleAnalyticsPlugin extends Plugin {
  get info(): PluginInfo {
    return {
      name: 'google-analytics',
      title: 'Google Analytics',
      disabledByDefault: false,
    }
  }

  async activate(registry: PluginRegistry): Promise<void> {
    const envConfigState = registry.get<any>('config')?.env ?? {}
    const gaId =
      envConfigState && 'VITE_GOOGLE_ANALYTICS_ID' in envConfigState
        ? envConfigState['VITE_GOOGLE_ANALYTICS_ID']
        : null

    if (!gaId) {
      console.warn(
        'Google analytics feature flag was activated but no GA ID was found in the environment variables.',
      )
      return
    }

    registry.subscribeEvent<AuthPlatformSetEvent>('auth.platform.set', event => {
      // Track sign up
      // -> Best way to do that seems to keep track of when a platform user has not been seen yet.
      if (event?.data?.user && !event.data.user.last_seen) {
        ReactGA.event({
          action: 'sign_up',
          value: 0,
          category: 'auth',
        })
      }
    })

    registry.subscribeEvent<AuthTokenSetEvent>('auth.token.set', event => {
      // Track log in
      // -> Best way to track is to look at when a user is redirected from the auth provider.
      if (event?.data?.trigger === 'auth-provider-redirect') {
        ReactGA.event({
          action: 'login',
          value: 0,
          category: 'auth',
        })
      }
    })

    registry.subscribeEvent('settings.profile-onboard.start', () => {
      ReactGA.event({
        action: 'tutorial_begin',
        value: 0,
        category: 'onboard',
      })
    })

    registry.subscribeEvent('settings.profile-onboard.end', () => {
      ReactGA.event({
        action: 'tutorial_complete',
        value: 0,
        category: 'onboard',
      })
    })

    // We don't have an accurate way of defining purchases in the app yet.
    // ==> To revisit when this becomes more important to track.
    // registry.subscribeEvent<{
    //   id: string
    //   amount: number
    //   currency: string
    // }>('payments.balance.topped-up', event => {
    //   if (!event?.amount || !event?.currency) return

    //   ReactGA.send({
    //     hitType: 'event',
    //     eventAction: 'purchase',
    //     eventCategory: 'payments',
    //     eventValue: event?.amount,
    //     currency: event?.currency,
    //   })
    // })

    registry.subscribeEvent('community.followers.link-shared', () => {
      ReactGA.send({
        hitType: 'event',
        eventAction: 'share',
        eventCategory: 'community',
        eventValue: 0,
        content_type: 'follower-link',
        method: 'phosphor-consumer-app',
      })
    })

    ReactGA.initialize(gaId)
  }
}
